"use strict";

import Vue from "vue"; //引入vue
import axios from "axios"; //引入axios
axios.defaults.headers.post["Content-Type"] = "application/json";

let config = {
  // baseURL: "api",
  // baseURL: "http://192.168.0.20:527/", //本地
  // baseURL: "http://110.41.2.216:8088/",
  baseURL: "https://apirsq.daoziji.com/", //正式
  // baseURL: "https://rsqapi.daoziji.com/", //测试
  // timeout: 6000, //请求超时时间
};

//创建一个axios请求对象 _axios
const _axios = axios.create(config);
//设置请求拦截器
_axios.interceptors.request.use(
  //创建请求成功重写请求参数数据
  function (config) {
    // Do something before request is sent
    return config;
  },
  //创建失败的时候 回调函数
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
//响应拦截器

_axios.interceptors.response.use(
  //响应成功处理回调函数
  function (response) {
    return response;
  },
  //响应失败处理回调函数
  function (error) {
    return Promise.reject(error);
  }
);

function Plugin() {}
//创建一个Vue的插件 插件构造函数的install函数会被Vue.use()自动执行
Plugin.install = function (Vue) {
  Vue.axios = _axios; //在Vue构造函数上面添加一个对象axios
  window.axios = _axios; //在全局window对象上面添加一个axios对象
  //在Vue构造函数的原型对象上面添加新的属性 axios $axios属性 他们都是axios对象
  //在组件对象里面 可以 this.axios 或 this.$axios都可以调用到axios对象
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};
//Vue.use() 安装插件 执行插件里面的install方法
Vue.use(Plugin);
//公开Plugin模块
export default _axios;
