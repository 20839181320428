import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./api/axios";
import _axios from './api/axios';
import { getToken } from "@/utils/auth";
import "./assets/style/index.scss";
import './permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './permission'
import "default-passive-events"
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle);
Vue.use(ElementUI);
Vue.config.productionTip = false
_axios.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers.Authentication = getToken()
  }
  return config
})
Vue.prototype.$http = _axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
