import Cookies from "js-cookie";

const TokenKey = "Contract-Token"; //cookie名字
const TokenId = 'docTemplateId'
const CompanyCertificationcookie = 'Company_Certification'
export function getToken() {
  return Cookies.get(TokenKey); //获取cookie
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 1 }); //设置cookie
}

export function removeToken() {
  return Cookies.remove(TokenKey); //删除cookie
}

export function getID() {
  return Cookies.get(TokenId); //获取cookie
}

export function setID(token) {
  return Cookies.set(TokenId, token,); //设置cookie
}

export function removeID() {
  return Cookies.remove(TokenId); //删除cookie
}


  export function getCompanyCertificationcookie() {
    return Cookies.get(CompanyCertificationcookie); //获取cookie
  }
  
  export function setCompanyCertificationcookie(token) {
    return Cookies.set(CompanyCertificationcookie, token, { expires: 30 }); //设置cookie
  }
  
  export function removeCompanyCertificationcookie() {
    return Cookies.remove(CompanyCertificationcookie); //删除cookie
  }
