<template>
  <div id="app">
    <div class="box" v-if="navData.length != 0">
      <div class="box_header">
        <div class="header_left" v-if="isCollapse == false">电子人事签系统</div>
        <div class="header_logo" v-else><img src="./assets/image/logo.png" alt=""></div>
        <div :class="['header_right', { 'isCollapseheader_right': isCollapse == true }]">
          <div>
            <div class="iconbox" @click="foldclick" v-if="isCollapse == false">
              <i class="el-icon-s-fold"></i>
            </div>
            <div class="iconbox" @click="foldclick" v-else>
              <i class="el-icon-s-unfold"></i>
            </div>
          </div>
          <div class="sculpturebox">
            <!-- <img src="../assets/image/sculpture.jpg" alt="" /> -->
            <!-- <div class="sculpturebox_left">{{ iponedata }}</div> -->
            <div class="sculpturebox_right" @click="centerDialogVisible = true">
              退出登录
            </div>
          </div>
        </div>
        <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
          <span>是否退出登录?</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="withdrawclick">确 定</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="box_content">
        <div class="box_sidebar">
          <el-menu :default-active="navrouterdata" class="el-menu-vertical-demo" background-color="#001529"
            text-color="#fff" active-text-color="#409eff" router :collapse="isCollapse" :collapse-transition="false">

            <div v-for="(item, index) in navData" :key="index">
              <div v-if="item.children">
                <el-submenu :index="item.path">
                  <template slot="title">
                    <i :class="item.meta.icon"></i>
                    <span slot="title" :class="{ titlespan: isCollapse == true }">{{ item.meta.title }}</span>
                  </template>

                  <div v-for="(itemfather, indetd) in item.children" :key="indetd">
                    <div v-if="itemfather.children">
                      <el-submenu :index="itemfather.path">
                        <template slot="title">
                          <i :class="itemfather.meta.icon"></i>
                          <span>{{ itemfather.meta.title }}</span>
                        </template>
                        <div v-for="(itemson, indetf) in itemfather.children" :key="indetf">
                          <el-menu-item :index="itemson.path">
                            <i :class="itemson.meta.icon"></i>
                            <span slot="title">{{ itemson.meta.title }}</span>
                          </el-menu-item>
                        </div>
                      </el-submenu>
                    </div>
                    <div v-else>
                      <el-menu-item :index="itemfather.path">
                        <i :class="itemfather.meta.icon"></i>
                        <span slot="title">{{ itemfather.meta.title }}</span>
                      </el-menu-item>
                    </div>


                  </div>
                </el-submenu>
              </div>
              <div v-else>
                <el-menu-item :index="item.path">
                  <i :class="item.meta.icon"></i>
                  <span slot="title">{{ item.meta.title }}</span>
                </el-menu-item>
              </div>
            </div>
          </el-menu>
        </div>
        <div :class="['content_right', { 'isCollapsecontent_right': isCollapse == true }]"><router-view
            v-wechat-title="$route.meta.title + '-电子人事签系统'" /></div>
      </div>
    </div>
    <div v-else><router-view /></div>
  </div>
</template>
<script>
import { removeToken } from "@/utils/auth";
import { mapGetters } from "vuex";
import store from "./store";
export default {
  computed: mapGetters(['navData', 'navrouterdata']),
  data() {
    return {
      hovershow: false,
      centerDialogVisible: false,
      iponedata: '',
      pathdata: '/',
      isCollapse: false,

    
    };
  },

  created() {
    this.iponedata = JSON.parse(localStorage.getItem("iponedata"));

   
  },
  
  methods: {
   

    //折叠菜单
    foldclick() {
      this.isCollapse = !this.isCollapse
    },
    enterclick() {
      this.hovershow = true;
    },
    outclick() {
      this.hovershow = false;
    },
    withdrawclick() {
      removeToken();
      store.dispatch('SETNAV', [])
      localStorage.clear()
      this.$router.push("/login");
      // this.$router.replace("/login")  
      this.centerDialogVisible = false
      this.$message("注销成功");
      window.location.reload()
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  background-color: #f0f2f5;
  cursor: pointer;
}

.box_header {
  width: 100%;
  height: 46px;
  display: flex;

  .header_left {
    width: 215px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    background-color: #001529;
    position: fixed;
    z-index: 2002;
  }

  .header_logo {
    width: 63px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #001529;

    img {
      height: 60%;
    }
  }

  .header_right {
    width: calc(100% - 215px);
    border-bottom: 1px solid #f0f2f5;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    background-color: #fff;
    margin-left: 216px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .iconbox {
      margin-left: 20px;
      font-size: 30px;
    }

    .sculpturebox {
      margin-right: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;

      .sculpturebox_left {
        margin-right: 10px;
      }

      .sculpturebox_right {
        color: #fff;
        border: 1px solid #409eff;
        background-color: #409eff;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }

  .isCollapseheader_right {
    flex: 1;
    margin-left: 0px;
  }
}

.titlespan {
  display: none;
}

.isCollapsecontent_right {
  margin: 46px 0 0 63px !important;
}

.box_content {
  width: 100%;
  height: 100vh;
  display: flex;
  margin-top: -46px;
}

.box_sidebar {
  width: 63px;
  height: 100vh;
  margin-top: 46px;
  background-color: #001529;
  position: fixed;
  z-index: 999;
}

.content_right {
  flex: 1;
  width: 0;
  margin: 46px 0 0 216px;
  // width: calc(100% - 216px) ;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 216px;
  min-height: 400px;
  height: calc(100vh - 46px);
}</style>
