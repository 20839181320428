
import Vue from 'vue'
import Vuex from 'vuex'
import _axios from '../api/axios';
// import { getToken } from "@/utils/auth";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tokendata: '',
    // NObar: true,
    routerArr: [],
    gainrouter: [],
    nav: [],  //1
    authenticationdata: '',
    routerdata: '',
  },
  getters: {
    navData: state => state.nav, //2
    navauthentication: state => state.authenticationdata, //2
    navrouterdata: state => state.routerdata
  },
  mutations: {
    // 修改token
    revisetoken(state, data) {
      state.tokendata = data
    },
    // 注册
    // reviseNObar(state, data) {
    //   state.NObar = data
    // },
    //存放路由
    // HEARDER(state, data) {
    //   state.routerArr = data
    //   console.log("存放", state.routerArr);
    // },
    //获取路由
    Gainrouter(state, data) {
      state.gainrouter = data
      // console.log("获取到路由了",state.gainrouter);
    },
    setnav(state, data) {   //3
      state.nav = data
    },
    setrouterdata(state, data) {
      state.routerdata = data
    },
    setauthentication(state, data) {
      state.authenticationdata = data
    }
  },
  actions: {
    // Nav(context, state) {
    //   context.commit('HEARDER', state)
    //   console.log("查看", state.routerArr);
    // },
    SETNAV({ commit }, data) {  //4
      commit('setnav', data)
    },
    SETauthentication({ commit }, data) {
      commit('setauthentication', data)
    },
    SETROUTERDATE({ commit }, data) {
      commit('setrouterdata', data)
    }
  },
  modules: {
  }
})
