import Vue from "vue";
import VueRouter from "vue-router";
import _axios from "../api/axios";
import store from "@/store";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from "@/utils/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      title: '电子人事签系统'
    }
  },
  {
    path: "/attestation",
    name: "attestation",
    component: () => import("../views/system/attestation/index"),
    meta: {
      title: '个人认证'
    }
  },
  {
    path: "/corporation",
    name: "corporation",
    component: () => import("../views/system/corporation/index"),
    meta: {
      title: '公司认证'
    }
  },
  {
    path: "/fabrication",
    name: "fabrication",
    component: () => import("../views/system/fabrication/index"),
    meta: {
      title: '制作合同'
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
    meta: {
      title: '测试'
    }
  },  
 
]

const router = new VueRouter({
  // mode: 'history', // 去掉url中的#
  mode: "hash",
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

const whiteList = ["/login"];
// 路由拦截
router.beforeEach(async (to, from, next) => {

  if (getToken()) {
    // store.commit('reviseNObar', true)
    if (to.path == '/login') {
      NProgress.start()
      next()
      NProgress.done()
    } else {
      NProgress.start()
      if (store.state.nav.length === 0) {
        let res = await _axios.get('/dzj-user/getRouters')
        let red = await _axios.get('/dzj-company/hrUserIdByCompany')
        if(res.data.data.length === 0){
          next("/login");
          return
        }
        store.dispatch('SETNAV', res.data.data)
        if (red.data.data == '') {
          store.dispatch('SETauthentication', 0)
        } else if (red.data.data != '') {
          // console.log(red.data.data[0].authStatus);
          if (red.data.data[0].authStatus == 0) {
            store.dispatch('SETauthentication', 1)
          } else {
            store.dispatch('SETauthentication', 2)
          }
        }
        let routerdata = routesData(res.data.data)
        routerdata.forEach((item) => {
          router.addRoute(item)
        })

        next({ path: to.path })
      
        store.dispatch('SETROUTERDATE', to.path)
      
        NProgress.done()
      } else {
        NProgress.start()
        next()
        store.dispatch('SETROUTERDATE', to.path)
        
        NProgress.done()
      }
    }
  } else {
    // store.commit('reviseNObar', false)
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
  }



})

function routesData(result) {
  // console.log("1111", result);
  var aaa = []
  result.forEach((item) => {
    if (item.children) {
      item.children.forEach((ited) => {
        if (ited.children) {
          // console.log(222);
        } else {
          aaa.push(ited)
        }
      })
    } else {
      aaa.push(item)
    }
  });
  var bbb = []
  aaa.forEach((item) => {
    bbb.push({
      path: item.path,
      name: item.name,
      meta: item.meta,
      component: () => import(`@/views/${item.component}`)
    })
  })
  return bbb
}
//防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default router